<!--
 * @Author: 月魂
 * @Date: 2020-12-11 11:29:06
 * @LastEditTime: 2021-01-25 16:04:24
 * @LastEditors: 月魂
 * @Description: 
 * @FilePath: \bingmayong-serbia\src\views\game\share.vue
-->
<template>
  <div class="content" @click.once="playMusic">
    <audio
      id="audio"
      class="audio"
      autoplay="autoplay"
      loop="loop"
      preload="auto"
      controls="controls"
      src="http://cdn.topartsintermational.com/newYear.mp3"
    ></audio>
    <el-button type="danger" class="index" @click="() => this.$router.push('/')"
      >Кућа</el-button
    >
    <div class="box">
      <img
        v-show="!dialogVisible"
        src="http://cdn.topartsintermational.com/share.gif"
        class="share"
        style="width: 800px"
      />
    </div>

    <!-- <div class="share-btn">
      <a
        href="http://twitter.com/share?url=https://cccsydney.org"
        target="_blank"
      >
        <img src="../../assets/t.png" width="48px" style="margin-right: 16px" />
      </a>
      <a
        href="http://www.facebook.com/sharer.php?u=https://cccsydney.org"
        target="_blank"
      >
        <img src="../../assets/f.png" width="48px" />
      </a>
    </div> -->
    <div class="cont">
      <div class="icon">
        <a
          href="http://twitter.com/share?url=https://cccsydney.org"
          target="_blank"
        >
          <img src="../../assets/t.png" width="30px" height="30px" />
        </a>
        <a
          href="http://www.facebook.com/sharer.php?u=https://cccsydney.org"
          target="_blank"
        >
          <img src="../../assets/f.png" width="30px" height="30px" />
        </a>
      </div>
      <div class="cont-menu">
        <!-- <p>Host:China Cultural Center（Sydney）</p>
        <p>Shaanxi Provincial Department of Cultureand Tourism</p>
        <p>
          Organizers：Shaanxi Wenyi Internatioanl Culture Development co., Ltd
        </p>
        <p>Special thanks:Emperor Qinshihuang's Mausoleum Site Museum</p> -->
        <el-row>
          <el-col :span="5">{{ $t("message.footer.left.title") }}</el-col>
          <el-col :span="19">
            <p>{{ $t("message.footer.left.item1") }}</p>
            <p>{{ $t("message.footer.left.item2") }}</p>
          </el-col>
        </el-row>
      </div>
      <div class="cont-menu">
        <!-- <p>Acknowledgment: Xi'an Glory Tang Culture & Arts Co., Ltd,</p>
        <p>
          Shaanxi sagacity film and television culture communication Co., Ltd
        </p>
        <p>Bilibili-Ancients Say Channel</p>
        <p>Prince Jiang and Brick of Philosophy</p> -->
        <el-row>
          <el-col :span="4">{{ $t("message.footer.right.title") }}</el-col>
          <el-col :span="20"
            ><p>{{ $t("message.footer.right.item") }}</p></el-col
          >
        </el-row>
      </div>
    </div>
    <!-- 模态框 -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="1200px"
      :show-close="false"
      custom-class="dialog"
      destroy-on-close
      :modal="false"
    >
      <video
        :src="selected"
        controls
        autoplay="autoplay"
        muted
        width="1200px"
        style="display: block"
        @ended="onEnded"
      ></video>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'Share',
  data () {
    return {
      dialogVisible: true,
      selected: 'http://cdn.topartsintermational.com/end1.mp4',
      videos: [
        {
          url: 'http://cdn.topartsintermational.com/end1.mp4'
        },
        {
          url: 'http://cdn.topartsintermational.com/end2.mp4'
        },
        {
          url: 'http://cdn.topartsintermational.com/end3.mp4'
        },
        {
          url: 'http://cdn.topartsintermational.com/end4.mp4'
        },
        {
          url: 'http://cdn.topartsintermational.com/end5.mp4'
        },
        {
          url: 'http://cdn.topartsintermational.com/end6.mp4'
        },
      ],
    }
  },
  mounted () {
    const id = this.$route.params.id
    this.selected = this.videos[id].url || this.videos[0].url
  },
  methods: {
    // 音乐播放
    playMusic () {
      const audio = document.getElementById('audio')
      audio.play()
    },
    onEnded () {
      this.dialogVisible = false
    },
  },
}
</script>

<style scoped lang="less">
.content {
  min-width: 1200px;
  min-height: 100vh;
  background: url("http://cdn.topartsintermational.com/gameBg.jpg") no-repeat;
  background-size: cover;
  .audio {
    display: none;
  }
  .box {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 140px;
    .share {
      display: block;
    }
  }
}

.index {
  position: absolute;
  top: 10%;
  left: 8%;
  color: #fff;
  font-size: 32px;
  background-color: #ca3540;
  z-index: 1;
}
// .share-btn {
//   position: fixed;
//   bottom: 96px;
//   left: 0;
//   right: 0;
//   margin: auto;
//   display: flex;
//   justify-content: center;
// }
.cont {
  position: absolute;
  bottom: 6%;
  left: 0;
  right: 0;
  width: 1200px;
  margin: auto;
  display: flex;
}
.cont-menu {
  width: 45%;
  color: #fff;
  padding-left: 16px;
}
.cont-menu p {
  margin: 0;
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  font-weight: 500;
}
</style>